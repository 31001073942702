import React from 'react';
import './login.css';

import { authenticateUser } from '../../services/user';

export default class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value,
            password: this.state.password,
        });
    }

    handlePasswordChange(event) {
        this.setState({
            email: this.state.email,
            password: event.target.value,
        });
    }

    handleLogin() {
        authenticateUser(this.state.email, this.state.password)
            .then( ({ user, token }) => {
                this.props.onLogin(user, token);
            })
            .catch( (message) => {
                window.alert(message);
            });
    }

    render() {
        return (
            <div className='login'>
                <h1>Sign In</h1>
                <table className='login'>
                    <tbody>
                        <tr>
                            <td>Email</td>
                            <td>
                                <input
                                    type='text'
                                    value={this.state.email}
                                    onChange={this.handleEmailChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Password</td>
                            <td>
                                <input
                                    type='password'
                                    value={this.state.password}
                                    onChange={this.handlePasswordChange}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button className='prominent' onClick={ () => this.handleLogin() }>Sign In</button>
            </div>
        );
    }

}

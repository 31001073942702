import React from 'react';

import InputModal from '../input-modal/input-modal';


export default class FieldList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showFieldNameModal: false
        };
    }

    onAddField() {
        this.setState({
            showFieldNameModal: true
        });
    }

    onFieldValueChange(event, index) {
        const values = this.props.values.slice();
        values[index] = event.target.value;
        this.props.onChange(this.props.names, values);
    }

    onDeleteField(index) {
        const names = this.props.names.slice();
        const values = this.props.values.slice();

        names.splice(index, 1);
        values.splice(index, 1);

        this.props.onChange(names, values);
    }

    onFieldNameSubmit(name) {
        const names = this.props.names.slice();
        const values = this.props.values.slice();

        names.push(name);
        values.push('');

        this.setState({
            showFieldNameModal: false
        });
        this.props.onChange(names, values);
    }

    onFieldNameCancel() {
        this.setState({
            showFieldNameModal: false
        });
    }

    render() {
        const dataRows = [];
        for (let i in this.props.names) {
            let fieldName = this.props.names[i];
            let fieldValue = this.props.values[i];

            dataRows.push(
                <tr key={i}>
                    <td><span className='field-name'>{fieldName}</span></td>
                    <td>
                        <input
                            type='text'
                            placeholder='Value'
                            onChange={(e) => this.onFieldValueChange(e, i)}
                            disabled={this.props.disableEdit}
                            value={fieldValue}
                        />
                    </td>
                    {this.props.disableEdit ?
                        null :
                        <td>
                            <button onClick={() => this.onDeleteField(i)}>Delete</button>
                        </td>
                    }
                </tr>
            );
        }

        return (
            <div>
                <table className='content'>
                    <tbody>
                        {dataRows}
                    </tbody>
                </table>

                {this.props.disableEdit ?
                    null :
                    <button className='add' onClick={ () => this.onAddField() }>Add Field</button>
                }

                <InputModal
                    title='New Field'
                    message='Enter a name for the new field.'
                    isOpen={this.state.showFieldNameModal}
                    onSubmit={(s) => this.onFieldNameSubmit(s)}
                    onCancel={() => this.onFieldNameCancel()}
                />
            </div>
        );
    }

}

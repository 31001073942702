// Copyright (C) 2021 5DT (Fifth Dimension Technologies)

import Axios from 'axios';

import {getAuthHeader} from './token';


async function createLicense(productId, licenseeId, data) {
    try {
        const response = await Axios.post(
            'api/v1/admin/license',
            { productId, licenseeId, data },
            getAuthHeader()
        );

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'License creation failed';
        } else {
            throw 'Something went wrong...';
        }
    }
}


async function updateLicense(id, data) {
    try {
        const response = await Axios.put(
            'api/v1/admin/license/' + id,
            { data: data },
            getAuthHeader()
        );

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'License update failed';
        } else {
            throw 'Something went wrong...';
        }
    }
}


async function reactivateLicense(id) {
    try {
        const response = await Axios.post(
            'api/v1/admin/license/' + id + '/activate',
            null,
            getAuthHeader()
        );

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'License reactivation failed';
        } else {
            throw 'Something went wrong...';
        }
    }
}


async function revokeLicense(id) {
    try {
        const response = await Axios.delete('api/v1/admin/license/' + id, getAuthHeader());

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'License revocation failed';
        } else {
            throw 'Something went wrong...';
        }
    }
}


export {
    createLicense,
    updateLicense,
    reactivateLicense,
    revokeLicense
}

import React from 'react';
import './navbar.css';

import {Link} from 'react-router-dom';

export default class Navbar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const options = [];
        const buttons = [];

        if (!this.props.hide) {
            for (let key in this.props.paths) {
                let target = this.props.paths[key];

                options.push(<Link key={key} to={target}>{key}</Link>);
            }

            for (let key in this.props.buttons) {
                let fn = this.props.buttons[key];

                buttons.push(<button className='light' key={key} onClick={fn}>{key}</button>);
            }
        }

        return (
            <div className='navbar'>
                <span className='navbar-title'>5DT License Portal</span>
                <div className='navbar-options'>
                    {options}
                </div>
                <div className='navbar-buttons'>
                    {buttons}
                </div>
            </div>
        );
    }

}

import React from 'react';
import Modal from 'react-modal';

import FieldList from '../field-list/field-list';
import {updateLicense, reactivateLicense, revokeLicense} from '../../services/license';
import ConfirmModal from '../confirm-modal/confirm-modal';

import './license-modal.css';


export default class LicenseModal extends React.Component {

    constructor(props) {
        super(props);

        this.modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        this.state = {
            editFields: false,
            editNames: null,
            editValues: null
        };
    }

    onClose() {
        this.setState({
            editFields: false,
            editNames: null,
            editValues: null
        });
        this.props.onClose();
    }

    onFieldsChange(names, values) {
        this.setState({
            editFields: this.state.editFields,
            editNames: names,
            editValues: values
        });
    }

    onEditSave() {
        if (this.state.editNames && this.state.editValues) {
            const data = {};
            for (let i in this.state.editNames) {
                data[this.state.editNames[i]] = this.state.editValues[i];
            }

            updateLicense(this.props.product.license._id, data)
                .then((_) => {
                    this.setState({
                        editFields: false,
                        editNames: null,
                        editValues: null
                    });

                    const newLicense = this.props.product.license;
                    newLicense.data = data;
                    this.props.onLicenseUpdated(newLicense);
                })
                .catch((err) => {
                    // TODO show dialog
                    console.log(err);
                })
        } else {
            this.setState({
                editFields: false,
                editNames: null,
                editValues: null
            });
        }

    }

    onEditCancel() {
        this.setState({
            editFields: false,
            editNames: null,
            editValues: null
        });
    }

    onAllowEdit() {
        this.setState({
            editFields: true,
            editNames: this.state.editNames,
            editValues: this.state.editValues
        });
    }

    onAfterOpen() {

    }

    onReactivate() {
        // confirm first
        const dialog = <ConfirmModal
            isOpen={true}
            title='Reactivate License?'
            message='This will invalidate any existing license tokens or activation codes.'

            onConfirm={() => {
                this.setState({
                    dialog: null
                });

                reactivateLicense(this.props.product.license._id)
                    .then(({ activationCode }) => {
                        this.props.onLicenseReactivated(activationCode);
                    })
                    .catch((err) => {
                        // TODO show dialog
                        console.log(err);
                    });
            }}

            onCancel={() => this.setState({
                dialog: null
            })}
        />;

        this.setState({
            dialog: dialog
        });
    }

    onRevoke() {
        // confirm first
        const dialog = <ConfirmModal
            isOpen={true}
            title='Revoke License?'
            message='This will invalidate any existing license tokens or activation codes and dissociate this product from the licensee.'

            onConfirm={() => {
                this.setState({
                    dialog: null
                });

                revokeLicense(this.props.product.license._id)
                    .then((_) => {
                        this.props.onLicenseRevoked();
                    })
                    .catch((err) => {
                        // TODO show dialog
                        console.log(err);
                    });
            }}

            onCancel={() => this.setState({
                dialog: null
            })}
        />;

        this.setState({
            dialog: dialog
        });
    }

    render() {
        let content;

        if (this.props.product && this.props.product.license) {
            const product = this.props.product;
            const license = this.props.product.license;

            let fieldNames;
            let fieldValues;
            if (this.state.editFields && this.state.editNames) {
                // edit already made, use the new values
                for (let i in this.state.editNames) {
                    fieldNames = this.state.editNames;
                    fieldValues = this.state.editValues;
                }
            } else {
                // no edits made, use the license's fields
                fieldNames = [];
                fieldValues = [];
                for (let key in license.data) {
                    fieldNames.push(key);
                    fieldValues.push(license.data[key]);
                }
            }

            let licensedTo = license.licensee;
            if (license.licensee && this.props.licensees) {
                // find the licensee with the assigned ID
                for (let i in this.props.licensees) {
                    let licensee = this.props.licensees[i];
                    if (licensee._id == licensedTo) {
                        licensedTo = licensee.name;
                        break;
                    }
                }
            }

            content = <div>
                <h2>{this.props.product.type} License</h2>
                <table className='content'>
                    <tbody>
                        <tr>
                            <td>Product ID</td>
                            <td colSpan='2'>{product._id}</td>
                        </tr>
                        <tr>
                            <td>License ID</td>
                            <td colSpan='2'>{license._id}</td>
                        </tr>
                        {licensedTo ?
                            <tr>
                                <td>Licensed To</td>
                                <td colSpan='2'>{licensedTo}</td>
                            </tr>
                            : null
                        }
                        <tr>
                            <td>Activation Code</td>
                            <td colSpan='2'>{
                                license.activationCode || 'Already activated'
                            }</td>
                        </tr>
                        <tr>
                            <td>Last Verified</td>
                            <td colSpan='2'>{
                                license.lastVerified ? new Date(license.lastVerified).toLocaleString() : 'Never'
                            }</td>
                        </tr>
                    </tbody>
                </table>

                <div className='field-header'>
                    <h3 className='inline'>Data Fields</h3>
                    {
                        this.state.editFields ?
                            <span>
                                <button onClick={() => this.onEditSave()}>Save</button>
                                <button onClick={() => this.onEditCancel()}>Cancel</button>
                            </span>
                            : <span>
                                <button onClick={() => this.onAllowEdit()}>Edit</button>
                            </span>
                    }
                </div>
                <FieldList
                    names={fieldNames}
                    values={fieldValues}
                    onChange={(n, v) => this.onFieldsChange(n, v)}
                    disableEdit={!this.state.editFields}
                />
            </div>;
        }

        return <Modal
            isOpen={this.props.isOpen}
            onAfterOpen={ () => this.onAfterOpen() }
            onRequestClose={ () => this.onClose() }
            style={this.modalStyle}
            ariaHideApp={false}
        >
            {content || <span>Something went wrong...</span>}
            <div className='modal-buttons'>
                <button className='prominent red' onClick={ () => this.onReactivate() }>Reactivate</button>
                <button className='prominent red' onClick={ () => this.onRevoke() }>Revoke</button>
                <button className='prominent' onClick={ () => this.onClose() }>Close</button>
            </div>

            {this.state.dialog}
        </Modal>
    }

}

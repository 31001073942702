import React from 'react';
import {Redirect} from 'react-router-dom';

import ProductList from '../../components/product-list/product-list';
import CreateProductModal from '../../components/create-product-modal/create-product-modal';
import {getProducts} from '../../services/product';

import './products.css';

export default class Products extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            products: null,
            showCreateProduct: false
        };

        this.loadProducts();
    }

    loadProducts() {
        getProducts()
            .then(({ products }) => {
                this.setState({
                    products: products,
                    showCreateProduct: false
                });
            })
            .catch((err) => {
                this.setState({
                    products: null,
                    showCreateProduct: false,
                    error: err
                });
            });
    }

    onCreateProduct() {
        // don't allow if not loaded yet
        if (this.state.products) {
            this.setState({
                products: this.state.products,
                showCreateProduct: true
            });
        }
    }

    onRefresh() {
        this.setState({
            products: null,
            showCreateProduct: false
        });

        this.loadProducts();
    }

    onProductModalClose() {
        this.setState({
            products: this.state.products,
            showCreateProduct: false
        });
    }

    onProductCreated(product) {
        // add the product to the list
        const products = this.state.products.slice();
        products.push(product);

        this.setState({
            products: products,
            showCreateProduct: false
        });
    }

    onLicenseCreated(index, license) {
        // add the license to the product
        const products = this.state.products.slice();
        products[index].license = license;

        this.setState({
            products: products,
            showCreateProduct: this.state.showCreateProduct
        });
    }

    onLicenseUpdated(index, license) {
        // update the license of the product
        const products = this.state.products.slice();
        products[index].license = license;

        this.setState({
            products: products,
            showCreateProduct: this.state.showCreateProduct
        });
    }

    onLicenseReactivated(index, activationCode) {
        // update the license's activation code
        const products = this.state.products.slice();
        products[index].license.activationCode = activationCode;

        this.setState({
            products: products,
            showCreateProduct: this.state.showCreateProduct
        });
    }

    onLicenseRevoked(index) {
        // remove the license of the product
        const products = this.state.products.slice();
        products[index].license = null;

        this.setState({
            products: products,
            showCreateProduct: this.state.showCreateProduct
        });
    }

    render() {
        if (!this.props.token) {
            return <Redirect to='/login' />
        }

        return (
            <div className='products-content'>
                <h1>Products</h1>
                <button
                    className='prominent'
                    disabled={!this.state.products}
                    onClick={() => this.onCreateProduct()}>Create</button>
                <button className='prominent' disabled={!this.state.products} onClick={() => this.onRefresh()}>Refresh</button>
                <ProductList
                    products={this.state.products}
                    onLicenseCreated={(i, l) => this.onLicenseCreated(i, l)}
                    onLicenseUpdated={(i, l) => this.onLicenseUpdated(i, l)}
                    onLicenseReactivated={(i, c) => this.onLicenseReactivated(i, c)}
                    onLicenseRevoked={(i) => this.onLicenseRevoked(i)}
                />
                <CreateProductModal
                    isOpen={this.state.showCreateProduct}
                    onProductCreated={(p) => this.onProductCreated(p)}
                    onClose={() => this.onProductModalClose()}
                />
            </div>
        );
    }

}

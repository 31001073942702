import React from 'react';

export default class NotFound extends React.Component {

    render() {
        return <div className='notfound'>
            404 Not Found
        </div>
    }

}

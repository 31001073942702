// Copyright (C) 2021 5DT (Fifth Dimension Technologies)

import jwt from 'jwt-decode';

function readAuthCookie() {
	const prefix = 'Auth=';
	const cookies = document.cookie.split(';');
	for (let i in cookies) {
		let cookie = cookies[i];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(prefix) == 0) {
            return cookie.substring(prefix.length, cookie.length);
        }
	}
	return null;
}


function setAuthCookie(token) {
    document.cookie = 'Auth=' + token + '; SameSite=Strict';
}


function clearAuthCookie() {
    document.cookie = 'Auth=; SameSite=Strict';
}


function getAuthHeader() {
    const config = {
        headers: {
            Authorization: 'Bearer ' + readAuthCookie()
        }
    }
    return config;
}


function getTokenObject() {
    const token = readAuthCookie();

    return token ? jwt(token) : {};
}


export {
    readAuthCookie,
    setAuthCookie,
    clearAuthCookie,
    getAuthHeader,
    getTokenObject
};

// Copyright (C) 2021 5DT (Fifth Dimension Technologies)

import Axios from 'axios';

import {getAuthHeader} from './token';


async function getProducts() {
    try {
        const response = await Axios.get('api/v1/admin/product', getAuthHeader());

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'Product fetch failed';
        } else {
            throw 'Something went wrong...';
        }
    }
}


async function getLicenseeProducts(licenseeId) {
    try {
        const response = await Axios.get(
            'api/v1/admin/licensee/' + licenseeId + '/products',
            getAuthHeader()
        );

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'Licensee product fetch failed'
        } else {
            throw 'Something went wrong...';
        }
    }
}


async function createProduct(type, data) {
    try {
        const response = await Axios.post(
            'api/v1/admin/product',
            {
                type: type,
                data: data
            },
            getAuthHeader()
        );

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'Product creation failed';
        } else {
            throw 'Something went wrong...';
        }
    }
}


async function updateProduct(id, data) {
    try {
        const response = await Axios.put(
            'api/v1/admin/product/' + id,
            { data: data },
            getAuthHeader()
        );

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'Product update failed';
        } else {
            throw 'Something went wrong...';
        }
    }
}


export {
    getProducts,
    getLicenseeProducts,
    createProduct,
    updateProduct,
}

import React from 'react';
import Modal from 'react-modal';

import {createLicensee} from '../../services/licensee';
import MessageModal from '../message-modal/message-modal';

import './create-licensee-modal.css';


export default class CreateLicenseeModal extends React.Component {

    constructor(props) {
        super(props);

        this.modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                minWidth: '50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        this.state = {
            name: '',
            contact: '',
            address: ''
        };
    }

    onClose() {
        this.props.onClose();
    }

    onAfterOpen() {

    }

    onNameChange(event) {
        this.setState({
            name: event.target.value,
            contact: this.state.contact,
            address: this.state.address
        })
    }

    onContactChange(event) {
        this.setState({
            name: this.state.name,
            contact: event.target.value,
            address: this.state.address
        })
    }

    onAddressChange(event) {
        this.setState({
            name: this.state.name,
            contact: this.state.contact,
            address: event.target.value
        })
    }

    onCreate() {
        if (this.state.name == '') {
            // show an error dialog
            const dialog = <MessageModal
                isOpen={true}
                title='No Name'
                message='A licensee name must be provided.'
                onClose={() => this.setState({
                    dialog: null
                })}
            />;

            this.setState({
                dialog: dialog
            });
            return;
        }

        createLicensee(this.state.name, this.state.contact, this.state.address)
            .then(({ licensee }) => {
                this.setState({
                    name: '',
                    contact: '',
                    address: ''
                });

                this.props.onLicenseeCreated(licensee);
            })
            .catch((err) => {
                console.log(err);
                // TODO show a dialog
            });
    }

    render() {
        return <Modal
            isOpen={this.props.isOpen}
            onAfterOpen={ () => this.onAfterOpen() }
            onRequestClose={ () => this.onClose() }
            style={this.modalStyle}
            ariaHideApp={false}
        >
            <h2>New Licensee</h2>

            <table>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td><input type='text' value={this.state.name} onChange={(e) => this.onNameChange(e)} /></td>
                    </tr>
                    <tr>
                        <td>Contact</td>
                        <td><input type='tel' value={this.state.contact} onChange={(e) => this.onContactChange(e)} /></td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td><input type='text' value={this.state.address} onChange={(e) => this.onAddressChange(e)} /></td>
                    </tr>
                </tbody>
            </table>
            <div className='modal-buttons'>
                <button className='prominent red' onClick={ () => this.onClose() }>Cancel</button>
                <button className='prominent' onClick={() => this.onCreate()}>Create</button>
            </div>

            {this.state.dialog}
        </Modal>
    }

}

import React from 'react';
import Modal from 'react-modal';

import FieldList from '../field-list/field-list';
import MessageModal from '../message-modal/message-modal';
import ConfirmModal from '../confirm-modal/confirm-modal';
import {createLicense} from '../../services/license';

import './create-license-modal.css';


export default class CreateLicenseModal extends React.Component {

    constructor(props) {
        super(props);

        this.modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        this.confirmedFields = false;

        this.state = {
            fieldNames: [],
            fieldValues: [],
            licensee: ''
        };
    }

    onClose() {
        this.props.onClose();
    }

    onAfterOpen() {

    }

    onCreate() {
        if (this.state.licensee == '') {
            // show an error dialog
            const dialog = <MessageModal
                isOpen={true}
                title='No Licensee'
                message='Choose a licensee to assign the license to.'
                onClose={() => this.setState({
                    dialog: null
                })}
            />;

            this.setState({
                dialog: dialog
            });
            return;
        }

        if (!this.confirmedFields && this.state.fieldNames.length == 0) {
            // show a confirm dialog
            const dialog = <ConfirmModal
                isOpen={true}
                title='No Fields'
                message='Create a license without any data fields?'
                onConfirm={() => {
                    this.setState({
                        dialog: null
                    });
                    this.confirmedFields = true;
                    this.onCreate();
                }}
                onCancel={() => this.setState({
                    dialog: null
                })}
            />;

            this.setState({
                dialog: dialog
            });
            return;
        }

        // create the data fields object
        const data = {};
        for (let i in this.state.fieldNames) {
            data[this.state.fieldNames[i]] = this.state.fieldValues[i];
        }

        // find the ID of the licensee
        let licenseeId;
        if (this.props.licensees) {
            for (let i in this.props.licensees) {
                const licensee = this.props.licensees[i];
                if (this.state.licensee == licensee.name) {
                    licenseeId = licensee._id;
                    break;
                }
            }
        }

        if (!licenseeId) {
            console.log('Invalid licensee chosen');
            // TODO show a dialog (though this shouldn't ever happen)
        }

        createLicense(this.props.product._id, licenseeId, data)
            .then(({ license }) => {
                this.setState({
                    fieldNames: [],
                    fieldValues: [],
                    licensee: ''
                });

                this.props.onLicenseCreated(license);
            })
            .catch((err) => {
                console.log(err);
                // TODO show a dialog
            });
    }

    onFieldsChange(names, values) {
        this.setState({
            fieldNames: names,
            fieldValues: values,
            licensee: this.state.licensee
        });
        this.confirmedFields = false;
    }

    onLicenseeChange(event) {
        this.setState({
            fieldNames: this.state.fieldNames,
            fieldValues: this.state.fieldValues,
            licensee: event.target.value
        });
    }

    render() {
        let content;

        if (this.props.product) {
            const product = this.props.product;

            content = <div>
                <h2>New {this.props.product.type} License</h2>

                <table>
                    <tbody>
                        <tr>
                            <td>Product ID</td>
                            <td>{product._id}</td>
                        </tr>
                        <tr>
                            <td>Assign to</td>
                            <td>
                            <select value={this.state.licensee} onChange={(e) => this.onLicenseeChange(e)}>
                                <option disabled></option>
                                {
                                    this.props.licensees ?
                                        this.props.licensees.map((l, i) => <option key={i}>{l.name}</option>)
                                        : null
                                }
                            </select>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h3>Data Fields</h3>

                <FieldList
                    names={this.state.fieldNames}
                    values={this.state.fieldValues}
                    onChange={(n, v) => this.onFieldsChange(n, v)}
                />

                <div className='modal-buttons'>
                    <button className='prominent red' onClick={ () => this.onClose() }>Cancel</button>
                    <button className='prominent' onClick={() => this.onCreate()}>Create</button>
                </div>
            </div>;
        }

        return <Modal
            isOpen={this.props.isOpen}
            onAfterOpen={ () => this.onAfterOpen() }
            onRequestClose={ () => this.onClose() }
            style={this.modalStyle}
            ariaHideApp={false}
        >
            {content || <span>Something went wrong...</span>}
            {this.state.dialog}
        </Modal>
    }

}

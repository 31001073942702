// Copyright (C) 2021 5DT (Fifth Dimension Technologies)

import Axios from 'axios';

import {getAuthHeader} from './token';


async function getLicensees() {
    try {
        const response = await Axios.get('api/v1/admin/licensee', getAuthHeader());

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'Licensee fetch failed';
        } else {
            throw 'Something went wrong...';
        }
    }
}


async function createLicensee(name, contact, address) {
    try {
        const response = await Axios.post(
            'api/v1/admin/licensee',
            {
                name: name,
                contact: contact,
                address: address
            },
            getAuthHeader()
        );

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'Licensee creation failed';
        } else {
            throw 'Something went wrong...';
        }
    }
}


async function updateLicensee(id, contact, address) {
    try {
        const response = await Axios.put(
            'api/v1/admin/licensee/' + id,
            { contact, address },
            getAuthHeader()
        );

        console.log(response.data.message);
        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'Licensee update failed';
        } else {
            throw 'Something went wrong...';
        }
    }
}


export {
    getLicensees,
    createLicensee,
    updateLicensee,
}

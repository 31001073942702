import React from 'react';
import {Redirect} from 'react-router-dom';

import {getLicensees} from '../../services/licensee';
import ProductModal from '../../components/product-modal/product-modal';
import CreateLicenseeModal from '../../components/create-licensee-modal/create-licensee-modal';
import EditLicenseeModal from '../../components/edit-licensee-modal/edit-licensee-modal';

import './licensees.css';

export default class Licensees extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            licensees: null,
            showProducts: null,
            editLicenseeIndex: null,
            showCreateLicensee: false
        };

        this.loadLicensees();
    }

    loadLicensees() {
        getLicensees()
            .then(({ licensees }) => {
                this.setState({
                    licensees: licensees,
                    showProducts: this.state.showProducts,
                    showCreateLicensee: this.state.showCreateLicensee,
                });
            })
            .catch((err) => {
                this.setState({
                    licensees: null,
                    showProducts: this.state.showProducts,
                    showCreateLicensee: this.state.showCreateLicensee,
                    error: err
                });
            });
    }

    handleViewProducts(licensee) {
        this.setState({
            licensees: this.state.licensees,
            showProducts: licensee,
            showCreateLicensee: this.state.showCreateLicensee
        });
    }

    onProductModalClose() {
        this.setState({
            licensees: this.state.licensees,
            showProducts: null,
            showCreateLicensee: this.state.showCreateLicensee
        });
    }

    onCreateLicensee() {
        this.setState({
            licensees: this.state.licensees,
            showProducts: this.state.showProducts,
            showCreateLicensee: true
        });
    }

    onRefresh() {
        this.setState({
            licensees: null,
            showProducts: null,
            showCreateLicensee: false
        });

        this.loadLicensees();
    }

    onLicenseeCreated(licensee) {
        const licensees = this.state.licensees.slice();
        licensees.push(licensee);

        this.setState({
            licensees: licensees,
            showProducts: this.state.showProducts,
            showCreateLicensee: false
        });
    }

    onCreateLicenseeModalClose() {
        this.setState({
            licensees: this.state.licensees,
            showProducts: this.state.showProducts,
            showCreateLicensee: false
        });
    }

    handleEditLicensee(index) {
        this.setState({
            editLicenseeIndex: index
        });
    }

    handleCloseLicenseeModal() {
        this.setState({
            editLicenseeIndex: null
        });
    }

    handleLicenseeEdited(licensee) {
        const licensees = this.state.licensees.slice();

        licensees[this.state.editLicenseeIndex] = licensee;

        this.setState({
            licensees: licensees,
            editLicenseeIndex: null
        });
    }

    render() {
        if (!this.props.token) {
            return <Redirect to='/login' />
        }

        let licenseeContent;
        if (this.state.licensees) {
            licenseeContent = <div>
                <button className='prominent' onClick={() => this.onCreateLicensee()}>Create</button>
                <button className='prominent' onClick={() => this.onRefresh()}>Refresh</button>
                <table className='content'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Products</th>
                            <th>Contact</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.licensees.map((licensee, i) =>
                                <tr key={i}>
                                    <td className='options'>
                                        <button onClick={() => this.handleEditLicensee(i)}><i className='fa fa-edit' /></button>
                                    </td>
                                    <td>{licensee.name}</td>
                                    <td>
                                        <button onClick={() => this.handleViewProducts(licensee)}>View</button>
                                    </td>
                                    <td>{licensee.contact}</td>
                                    <td>{licensee.address}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>;
        } else {
            licenseeContent = <div className='loading'>Loading...</div>;
        }

        return (
            <div className='licensees-content'>
                <h1>Licensees</h1>
                {licenseeContent}
                <ProductModal
                    isOpen={this.state.showProducts != null}
                    onClose={() => this.onProductModalClose()}
                    licensee={this.state.showProducts}
                />
                <CreateLicenseeModal
                    isOpen={this.state.showCreateLicensee}
                    onClose={() => this.onCreateLicenseeModalClose()}
                    onLicenseeCreated={(l) => this.onLicenseeCreated(l)}
                />
                <EditLicenseeModal
                    isOpen={this.state.editLicenseeIndex != null}
                    licensee={ this.state.editLicenseeIndex != null && this.state.licensees[this.state.editLicenseeIndex] }
                    onClose={() => this.setState({ editLicenseeIndex: null})}
                    onLicenseeEdited={(l) => this.handleLicenseeEdited(l)}
                />
            </div>
        );
    }

}

import React from 'react';
import Modal from 'react-modal';

import {createUser} from '../../services/user';
import MessageModal from '../message-modal/message-modal';


export default class CreateUserModal extends React.Component {

    constructor(props) {
        super(props);

        this.modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                minWidth: '50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        this.state = {
            email: '',
            name: '',
            password: '',
            superadmin: false
        };
    }

    onClose() {
        this.props.onClose();
    }

    onAfterOpen() {

    }

    onEmailChange(event) {
        this.setState({
            email: event.target.value
        });
    }

    onNameChange(event) {
        this.setState({
            name: event.target.value
        });
    }

    onPasswordChange(event) {
        this.setState({
            password: event.target.value
        });
    }

    onSuperadminChange(event) {
        this.setState({
            superadmin: event.target.checked
        });
    }

    onCreate() {
        if (this.state.email == '' || this.state.name == '' || this.state.password == '') {
            // show an error dialog
            const dialog = <MessageModal
                isOpen={true}
                title='Value Missing'
                message='All fields are required.'
                onClose={() => this.setState({
                    dialog: null
                })}
            />;

            this.setState({
                dialog: dialog
            });
            return;
        }

        createUser(this.state.email, this.state.password, this.state.name, this.state.superadmin)
            .then(({ user }) => {
                this.setState({
                    email: '',
                    name: '',
                    password: '',
                    superadmin: false
                });

                this.props.onUserCreated(user);
            })
            .catch((err) => {
                console.log(err);
                // TODO show dialog
            });
    }

    render() {
        return <Modal
            isOpen={this.props.isOpen}
            onAfterOpen={ () => this.onAfterOpen() }
            onRequestClose={ () => this.onClose() }
            style={this.modalStyle}
            ariaHideApp={false}
        >
            <h2>New User</h2>

            <table>
                <tbody>
                    <tr>
                        <td>Email</td>
                        <td><input type='text' value={this.state.email} onChange={(e) => this.onEmailChange(e)} /></td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td><input type='text' value={this.state.name} onChange={(e) => this.onNameChange(e)} /></td>
                    </tr>
                    <tr>
                        <td>Password</td>
                        <td><input type='password' value={this.state.password} onChange={(e) => this.onPasswordChange(e)} /></td>
                    </tr>
                    <tr>
                        <td>Superadmin</td>
                        <td><input type='checkbox' value={this.state.superadmin} onChange={(e) => this.onSuperadminChange(e)} /></td>
                    </tr>
                </tbody>
            </table>
            <div className='modal-buttons'>
                <button className='prominent red' onClick={ () => this.onClose() }>Cancel</button>
                <button className='prominent' onClick={() => this.onCreate()}>Create</button>
            </div>

            {this.state.dialog}
        </Modal>
    }

}

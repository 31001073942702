import React from 'react';
import Modal from 'react-modal';

export default class InputModal extends React.Component {

    constructor(props) {
        super(props);

        this.modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };
    }

    onAfterOpen() {

    }

    render() {
        return <Modal
            isOpen={this.props.isOpen}
            onAfterOpen={ () => this.onAfterOpen() }
            onRequestClose={ () => this.props.onClose() }
            style={this.modalStyle}
            ariaHideApp={false}
        >
            <h3>{this.props.title}</h3>
            <div>{this.props.message}</div>
            <div className='modal-buttons'>
                <button className='prominent' onClick={ () => this.props.onClose() }>
                    {this.props.buttonLabel || 'OK'}
                </button>
            </div>
        </Modal>
    }

}

import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './product-list.css'

import LicenseModal from '../license-modal/license-modal';
import CreateLicenseModal from '../create-license-modal/create-license-modal';
import MessageModal from '../message-modal/message-modal';
import ConfirmModal from '../confirm-modal/confirm-modal';
import {getLicensees} from '../../services/licensee';

export default class ProductList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            licensees: null,
            showLicenseIndex: null,
            createLicenseIndex: null
        };

        this.loadLicensees();
    }

    loadLicensees() {
        getLicensees()
            .then(({licensees}) => {
                this.setState({
                    licensees
                });
            })
            .catch((err) => {
                // TODO show error dialog and disable create license
            })
    }

    showMessage(title, message) {
        const dialog = <MessageModal
            isOpen={true}
            title={title}
            message={message}
            onClose={() => {
                this.setState({ dialog: null });
            }}
        />;

        this.setState({ dialog: dialog });
    }

    onShowLicense(productIndex) {
        this.setState({
            showLicenseIndex: productIndex,
            createLicenseIndex: this.state.createLicenseIndex
        });
    }

    onLicenseModalClose() {
        this.setState({
            showLicenseIndex: null,
            createLicenseIndex: this.state.createLicenseIndex
        });
    }

    onCreateLicense(productIndex) {
        this.setState({
            showLicenseIndex: this.state.showLicenseIndex,
            createLicenseIndex: productIndex
        });
    }

    onCreateLicenseModalClose() {
        this.setState({
            showLicenseIndex: this.state.showLicenseIndex,
            createLicenseIndex: null
        });
    }

    onLicenseCreated(license) {
        const index = this.state.createLicenseIndex;
        this.setState({
            showLicenseIndex: this.state.createLicenseIndex,
            createLicenseIndex: null
        });

        this.props.onLicenseCreated(index, license);
    }

    onLicenseUpdated(license) {
        this.props.onLicenseUpdated(this.state.showLicenseIndex, license);
    }

    onLicenseReactivated(activationCode) {
        this.props.onLicenseReactivated(this.state.showLicenseIndex, activationCode);
    }

    onLicenseRevoked() {
        const index = this.state.showLicenseIndex;
        this.setState({
            showLicenseIndex: null,
            createLicenseIndex: this.state.createLicenseIndex
        });

        this.props.onLicenseRevoked(index);
    }

    render() {
        if (this.props.products) {
            const productList = [];

            // gather headers from product data fields
            const dataHeaders = [];
            for (let i in this.props.products) {
                const data = this.props.products[i].data;
                for (let key in data) {
                    if (!dataHeaders.includes(key)) {
                        dataHeaders.push(key);
                    }
                }
            }

            for (let i in this.props.products) {
                const product = this.props.products[i];
                const row = (
                    <tr key={i} className='entry'>
                        <td className='id'>{product._id}</td>
                        <td className='type'>{product.type}</td>
                        <td>{
                            product.license ?
                                <button onClick={() => this.onShowLicense(i)}><i className='fa fa-eye' /> View</button>
                                : <button disabled={!this.state.licensees} onClick={() => this.onCreateLicense(i)}><i className='fa fa-plus' /> Create</button>
                        }</td>
                        {
                            dataHeaders.map(key => {
                                return <td key={key} className='data'>{product.data[key]}</td>
                            })
                        }
                    </tr>
                );
                productList.push(row);
            }

            return (
                <div className='product-list'>
                    <table className='content'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Type</th>
                                <th>License</th>
                                {dataHeaders.map(h => <th key={h}>{h}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {productList}
                            {
                                this.props.products.length == 0 ?
                                    <tr>
                                        <td colSpan='3'>There are no products yet</td>
                                    </tr>
                                    : null
                            }
                        </tbody>
                    </table>
                    <LicenseModal
                        isOpen={this.state.showLicenseIndex != null}
                        onClose={ () => this.onLicenseModalClose() }
                        product={ this.props.products[this.state.showLicenseIndex] }
                        licensees={ this.state.licensees }
                        onLicenseUpdated={ (l) => this.onLicenseUpdated(l) }
                        onLicenseReactivated={ (c) => this.onLicenseReactivated(c) }
                        onLicenseRevoked={ () => this.onLicenseRevoked() }
                    />
                    <CreateLicenseModal
                        isOpen={this.state.createLicenseIndex != null}
                        onClose={ () => this.onCreateLicenseModalClose() }
                        onLicenseCreated={ (l) => this.onLicenseCreated(l) }
                        product={ this.props.products[this.state.createLicenseIndex] }
                        licensees={ this.state.licensees }
                    />

                    {this.state.dialog}
                </div>
            );
        } else {
            return <div className='product-list'></div>
        }
    }

}

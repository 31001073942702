import React from 'react';
import Modal from 'react-modal';

import {createUser, updateUser} from '../../services/user';
import MessageModal from '../message-modal/message-modal';


export default class CreateUserModal extends React.Component {

    constructor(props) {
        super(props);

        this.modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                minWidth: '50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        this.state = {
            name: null,
            password: null,
            superadmin: null
        };
    }

    onClose() {
        this.props.onClose();
    }

    onAfterOpen() {
        this.setState({
            name: null,
            password: null,
            superadmin: null
        })
    }

    onNameChange(event) {
        this.setState({
            name: event.target.value
        });
    }

    onPasswordChange(event) {
        this.setState({
            password: event.target.value
        });
    }

    onSuperadminChange(event) {
        this.setState({
            superadmin: event.target.checked
        });
    }

    handleSave() {
        // default name and pass to "unchanged" (null) if they are empty
        let name = this.state.name;
        if (name == '') {
            name = null;
        }

        let password = this.state.password;
        if (password == '') {
            password = null;
        }

        let superadmin = this.state.superadmin;

        updateUser(this.props.user._id, password, name, superadmin)
            .then((_) => {
                this.setState({
                    name: null,
                    password: null,
                    superadmin: null
                });

                const newUser = Object.assign({}, this.props.user);

                if (name) newUser.name = name;
                if (superadmin != null) newUser.superadmin = Boolean(superadmin);

                this.props.onUserEdited(newUser);
            })
            .catch((err) => {
                console.log(err);
                // TODO show dialog
            });
    }

    render() {
        let content;

        if (this.props.user) {
            content = <div>
                <h2>Edit User</h2>

                <table>
                    <tbody>
                        <tr>
                            <td>ID</td>
                            <td>{this.props.user._id}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{this.props.user.email}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input type='text' value={this.state.name || this.props.user.name} onChange={(e) => this.onNameChange(e)} /></td>
                        </tr>
                        <tr>
                            <td>Password</td>
                            <td><input type='password' value={this.state.password || '...'} onChange={(e) => this.onPasswordChange(e)} /></td>
                        </tr>
                        <tr>
                            <td>Superadmin</td>
                            <td>
                                <input
                                    type='checkbox'
                                    checked={
                                        this.state.superadmin != null ?
                                            this.state.superadmin
                                            : this.props.user.superadmin
                                    }
                                    onChange={(e) => this.onSuperadminChange(e)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='modal-buttons'>
                    <button className='prominent red' onClick={ () => this.onClose() }>Cancel</button>
                    <button className='prominent' onClick={() => this.handleSave()}>Save</button>
                </div>
            </div>;
        }

        return <Modal
            isOpen={this.props.isOpen}
            onAfterOpen={ () => this.onAfterOpen() }
            onRequestClose={ () => this.handleSave() }
            style={this.modalStyle}
            ariaHideApp={false}
        >
            {content || 'Something went wrong...'}

            {this.state.dialog}
        </Modal>
    }

}

import React from 'react';
import {Redirect} from 'react-router-dom';

import CreateUserModal from '../../components/create-user-modal/create-user-modal';
import EditUserModal from '../../components/edit-user-modal/edit-user-modal';
import ConfirmModal from '../../components/confirm-modal/confirm-modal';
import {getUsers, deleteUser} from '../../services/user';

import './users.css';

export default class Users extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            users: null,
            showCreateUser: false
        };

        this.loadUsers();
    }

    loadUsers() {
        getUsers()
            .then(({users}) => {
                this.setState({
                    users: users
                });
            })
            .catch((err) => {
                this.setState({
                    error: err
                });
            });
    }

    onCreateUser() {
        this.setState({
            showCreateUser: true
        });
    }

    onRefresh() {
        this.setState({
            users: null,
            showCreateUser: false
        });

        this.loadUsers();
    }

    onUserCreated(user) {
        const users = this.state.users.slice();
        users.push(user);

        this.setState({
            users: users,
            showCreateUser: false
        });
    }

    onCreateUserModalClose() {
        this.setState({
            showCreateUser: false
        });
    }

    handleEditUser(index) {
        this.setState({
            editUserIndex: index
        });
    }

    handleDeleteUser(index) {
        const confirmDialog = <ConfirmModal
            isOpen={true}
            title={'Delete user "' + this.state.users[index].email + '"?'}
            message='This cannot be undone.'
            onConfirm={() => {
                deleteUser(this.state.users[index]._id)
                    .then((_) => {
                        // remove the user from the list
                        const users = this.state.users.slice();
                        users.splice(index, 1);

                        this.setState({ users: users, dialog: null });
                    })
                    .catch((err) => {
                        console.log(err);
                        // TODO show dialog
                        this.setState({ dialog: null });
                    });
            }}
            onCancel={() => {
                this.setState({ dialog: null });
            }}
        />;

        this.setState({ dialog: confirmDialog });
    }

    handleUserEdited(user) {
        const users = this.state.users.slice();

        users[this.state.editUserIndex] = user;

        this.setState({
            users: users,
            editUserIndex: null
        });
    }

    render() {
        if (!this.props.token) {
            return <Redirect to='/login' />
        }

        let userContent;
        if (this.state.users) {
            userContent = <div>
                <button className='prominent' onClick={() => this.onCreateUser()}>Create</button>
                <button className='prominent' onClick={() => this.onRefresh()}>Refresh</button>
                <table className='content'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Superadmin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.users.map((user, i) =>
                                <tr key={i}>
                                    <td className='options'>
                                        <button onClick={() => this.handleEditUser(i)}><i className='fa fa-edit' /></button>
                                        <button onClick={() => this.handleDeleteUser(i)}><i className='fa fa-trash' /></button>
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.name}</td>
                                    <td>{user.superadmin ? 'Yes' : 'No'}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>;
        } else {
            userContent = <div className='loading'>{this.state.error || 'Loading...'}</div>;
        }

        return (
            <div className='users-content'>
                <h1>Users</h1>
                {userContent}
                <CreateUserModal
                    isOpen={this.state.showCreateUser}
                    onClose={() => this.onCreateUserModalClose()}
                    onUserCreated={(l) => this.onUserCreated(l)}
                />
                <EditUserModal
                    isOpen={this.state.editUserIndex != null}
                    user={this.state.editUserIndex != null && this.state.users[this.state.editUserIndex]}
                    onClose={() => this.setState({ editUserIndex: null })}
                    onUserEdited={(u) => this.handleUserEdited(u)}
                />
                {this.state.dialog}
            </div>
        );
    }

}

import React from 'react';
import Modal from 'react-modal';

export default class InputModal extends React.Component {

    constructor(props) {
        super(props);

        this.modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        this.state = { input: '' };
    }

    onCancel() {
        this.props.onCancel();
    }

    onSubmit() {
        if (this.props.allowEmpty || this.state.input != '') {
            this.props.onSubmit(this.state.input);
            this.setState({
                input: ''
            });
        }
    }

    onAfterOpen() {

    }

    onInputChange(evt) {
        this.setState({
            input: evt.target.value
        });
    }

    render() {
        return <Modal
            isOpen={this.props.isOpen}
            onAfterOpen={ () => this.onAfterOpen() }
            onRequestClose={ () => this.onCancel() }
            style={this.modalStyle}
            ariaHideApp={false}
        >
            <h3>{this.props.title}</h3>
            <div className='modal-message'>
                {this.props.message}
            </div>
            <div>
                <input value={this.state.input} onChange={(e) => this.onInputChange(e)} />
            </div>
            <div className='modal-buttons'>
                <button className='prominent red' onClick={ () => this.onCancel() }>Cancel</button>
                <button className='prominent' onClick={ () => this.onSubmit() }>Submit</button>
            </div>
        </Modal>
    }

}

import React from 'react';
import Modal from 'react-modal';

import FieldList from '../field-list/field-list';
import MessageModal from '../message-modal/message-modal';
import ConfirmModal from '../confirm-modal/confirm-modal';
import {createProduct} from '../../services/product';

import './create-product-modal.css';


export default class CreateProductModal extends React.Component {

    constructor(props) {
        super(props);

        this.modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                minWidth: '50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        this.confirmedFields = false;

        this.state = {
            fieldNames: [],
            fieldValues: [],
            type: ''
        };
    }

    onClose() {
        this.props.onClose();
    }

    onAfterOpen() {

    }

    onCreate() {
        if (this.state.type == '') {
            // show an error dialog
            const dialog = <MessageModal
                isOpen={true}
                title='No Type'
                message='The product type can not be empty.'
                onClose={() => this.setState({
                    dialog: null
                })}
            />;

            this.setState({
                dialog: dialog
            });
            return;
        }

        if (!this.confirmedFields && this.state.fieldNames.length == 0) {
            // show a confirm dialog
            const dialog = <ConfirmModal
                isOpen={true}
                title='No Fields'
                message='Create a product without any data fields?'
                onConfirm={() => {
                    this.setState({
                        dialog: null
                    });
                    this.confirmedFields = true;
                    this.onCreate();
                }}
                onCancel={() => this.setState({
                    dialog: null
                })}
            />;

            this.setState({
                dialog: dialog
            });
            return;
        }

        const data = {};
        for (let i in this.state.fieldNames) {
            data[this.state.fieldNames[i]] = this.state.fieldValues[i];
        }

        createProduct(this.state.type, data)
            .then(({ product }) => {
                this.setState({
                    fieldNames: [],
                    fieldValues: [],
                    type: this.state.type
                });

                this.props.onProductCreated(product);
            })
            .catch((err) => {
                console.log(err);
                // TODO show a dialog
            });
    }

    onFieldsChange(names, values) {
        this.setState({
            fieldNames: names,
            fieldValues: values,
        });
        this.confirmedFields = false;
    }

    onTypeChange(event) {
        this.setState({
            fieldNames: this.state.fieldNames,
            fieldValues: this.state.fieldValues,
            type: event.target.value
        });
    }

    render() {
        return <Modal
            isOpen={this.props.isOpen}
            onAfterOpen={ () => this.onAfterOpen() }
            onRequestClose={ () => this.onClose() }
            style={this.modalStyle}
            ariaHideApp={false}
        >
            <h2>New Product</h2>

            <div>
                <h3>Product Type</h3>
                <input placeholder='None' type='text' value={this.state.type} onChange={(e) => this.onTypeChange(e)} />
            </div>

            <div className='fields'>
                <h3>Data Fields</h3>

                <FieldList
                    names={this.state.fieldNames}
                    values={this.state.fieldValues}
                    onChange={(n, v) => this.onFieldsChange(n, v)}
                />

            </div>

            <div className='modal-buttons'>
                <button className='prominent red' onClick={ () => this.onClose() }>Cancel</button>
                <button className='prominent' onClick={() => this.onCreate()}>Create</button>
            </div>

            {this.state.dialog}
        </Modal>
    }

}

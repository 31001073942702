import React from 'react';
import Modal from 'react-modal';

import {updateLicensee} from '../../services/licensee';
import MessageModal from '../message-modal/message-modal';


export default class EditLicenseeModal extends React.Component {

    constructor(props) {
        super(props);

        this.modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                minWidth: '50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        this.state = {
            contact: '',
            address: ''
        };
    }

    onClose() {
        this.props.onClose();
    }

    onAfterOpen() {
        this.setState({
            contact: this.props.licensee.contact,
            address: this.props.licensee.address
        });
    }

    onContactChange(event) {
        this.setState({
            contact: event.target.value,
            address: this.state.address
        })
    }

    onAddressChange(event) {
        this.setState({
            contact: this.state.contact,
            address: event.target.value
        })
    }

    onSave() {
        updateLicensee(this.props.licensee._id, this.state.contact, this.state.address)
            .then((_) => {
                const newLicensee = Object.assign({}, this.props.licensee);
                newLicensee.contact = this.state.contact;
                newLicensee.address = this.state.address;

                this.props.onLicenseeEdited(newLicensee);
            })
            .catch((err) => {
                console.log(err);
                // TODO show a dialog
            });
    }

    render() {
        let content;

        if (this.props.licensee) {
            content = <div>

                <table>
                    <tbody>
                        <tr>
                            <td>ID</td>
                            <td>{this.props.licensee._id}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{this.props.licensee.name}</td>
                        </tr>
                        <tr>
                            <td>Contact</td>
                            <td><input type='tel' value={this.state.contact} onChange={(e) => this.onContactChange(e)} /></td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td><input type='text' value={this.state.address} onChange={(e) => this.onAddressChange(e)} /></td>
                        </tr>
                    </tbody>
                </table>
                <div className='modal-buttons'>
                    <button className='prominent red' onClick={ () => this.onClose() }>Cancel</button>
                    <button className='prominent' onClick={() => this.onSave()}>Save</button>
                </div>
            </div>
        }

        return <Modal
            isOpen={this.props.isOpen}
            onAfterOpen={ () => this.onAfterOpen() }
            onRequestClose={ () => this.onClose() }
            style={this.modalStyle}
            ariaHideApp={false}
        >
            <h2>Edit Licensee</h2>
            {content || 'Something went wrong...'}
            {this.state.dialog}
        </Modal>
    }

}

import React from 'react';
import Modal from 'react-modal';

import {getLicenseeProducts} from '../../services/product';
import ProductList from '../product-list/product-list';

import './product-modal.css';


export default class ProductModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadedProducts: null,
            error: null
        };

        this.modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };
    }

    onClose() {
        this.setState({
            loadedProducts: null,
            error: null
        });
        this.props.onClose();
    }

    onAfterOpen() {
        if (!this.state.loadedProducts)
        {
            if (this.props.licensee) {
                getLicenseeProducts(this.props.licensee._id)
                    .then(({ products }) => {
                        this.setState({
                            loadedProducts: products,
                            error: null
                        });
                    })
                    .catch((err) => {
                        this.setState({
                            loadedProducts: null,
                            error: err
                        });
                    });
            } else {
                this.setState({
                    loadedProducts: null,
                    error: 'No licensee information',
                });
            }
        }
    }

    onLicenseUpdated(index, license) {
        // update the license of the product
        const products = this.state.loadedProducts.slice();
        products[index].license = license;

        this.setState({
            loadedProducts: products
        });
    }

    onLicenseReactivated(index, activationCode) {
        // update the license's activation code
        const products = this.state.loadedProducts.slice();
        products[index].license.activationCode = activationCode;

        this.setState({
            loadedProducts: products
        });
    }

    onLicenseRevoked(index) {
        // remove the license of the product
        const products = this.state.loadedProducts.slice();
        products[index].license = null;

        this.setState({
            loadedProducts: products
        });
    }

    render() {
        let content;

        if (this.state.loadedProducts) {
            content = <div>
                <ProductList
                    products={this.state.loadedProducts}
                    onLicenseUpdated={(i, l) => this.onLicenseUpdated(i, l)}
                    onLicenseReactivated={(i, c) => this.onLicenseReactivated(i, c)}
                    onLicenseRevoked={(i) => this.onLicenseRevoked(i)}
                />
            </div>;
        } else {
            content = <div className='loading'>{this.state.error || 'Loading...'}</div>;
        }

        return <Modal
            isOpen={this.props.isOpen}
            onAfterOpen={ () => this.onAfterOpen() }
            onRequestClose={ () => this.onClose() }
            style={this.modalStyle}
            ariaHideApp={false}
        >
            <button className='close' onClick={ () => this.onClose() }>close</button>
            {content}
        </Modal>
    }

}

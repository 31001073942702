import React from 'react';
import './app.css';

import Navbar from './components/navbar/navbar';
import NotFound from './components/not-found/not-found';

import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';

import Login from './pages/login/login';
import Products from './pages/products/products';
import Licensees from './pages/licensees/licensees';
import Users from './pages/users/users';

import {readAuthCookie, setAuthCookie, clearAuthCookie, getTokenObject} from './services/token';


export default class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            token: readAuthCookie()
        };

        this.handleLogin = this.handleLogin.bind(this);
    }

    handleLogin(_user, token) {
        setAuthCookie(token);
        this.setState({
            token: token
        });
        console.log(getTokenObject());
    }

    handleSignOut() {
        clearAuthCookie();
        this.setState({
            token: null
        });
    }

    render() {
        const navbarPaths = {
            'Products': '/',
            'Licensees': '/licensees'
        };
        if (getTokenObject().sa) { // only show to superadmin
            navbarPaths['Users'] = '/users';
        }

        const navbarButtons = {
            'Sign Out': () => this.handleSignOut()
        }

        return (
            <BrowserRouter>
                <Navbar hide={!this.state.token} paths={navbarPaths} buttons={navbarButtons} />
                <div className='content'>
                    <Switch>
                        <Route exact path='/'>
                            <Products
                                token={this.state.token}
                                onTimeout={() => this.handleSignOut()}
                            />
                        </Route>
                        <Route exact path='/licensees'>
                            <Licensees
                                token={this.state.token}
                                onTimeout={() => this.handleSignOut()}
                            />
                        </Route>
                        <Route exact path='/login'>
                            { // redirect to '/' if the token is already set
                                (this.state.token) ?
                                    <Redirect to='/' />
                                    : <Login onLogin={this.handleLogin} />
                            }
                        </Route>
                        <Route exact path='/users'>
                            <Users
                                token={this.state.token}
                                onTimeout={() => this.handleSignOut()}
                            />
                        </Route>
                        <Route>
                            <NotFound />
                        </Route>
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }

}

// Copyright (C) 2021 5DT (Fifth Dimension Technologies)

import Axios from 'axios';
import { getAuthHeader } from './token';


async function authenticateUser(email, password) {
    try {
        const response = await Axios.post('api/v1/admin/user/auth', {
            email: email,
            password: password
        });

        console.log(response.data.message);

        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'User authentication failed';
        } else {
            console.log(e);
            throw 'Something went wrong...';
        }
    }
}


async function getUsers() {
    try {
        const response = await Axios.get('api/v1/admin/user', getAuthHeader());
        console.log(response.data.message);

        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'User fetch failed';
        } else {
            console.log(e);
            throw 'Something went wrong...';
        }
    }
}


async function createUser(email, password, name, superadmin) {
    try {
        const response = await Axios.post(
            'api/v1/admin/user',
            {
                email: email,
                password: password,
                name: name,
                superadmin: Boolean(superadmin)
            },
            getAuthHeader()
        );
        console.log(response.data.message);

        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'User creation failed';
        } else {
            console.log(e);
            throw 'Something went wrong...';
        }
    }
}


async function updateUser(id, password, name, superadmin) {
    try {
        const body = {};
        if (password) body.password = password;
        if (name) body.name = name;
        if (superadmin != null) body.superadmin = Boolean(superadmin);

        if (!password && !name && superadmin == null) {
            return null; // nothing to change
        }

        const response = await Axios.put(
            'api/v1/admin/user/' + id,
            body,
            getAuthHeader()
        );
        console.log(response.data.message);

        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'User update failed';
        } else {
            console.log(e);
            throw 'Something went wrong...';
        }
    }
}


async function deleteUser(id) {
    try {
        const response = await Axios.delete('api/v1/admin/user/' + id, getAuthHeader());
        console.log(response.data.message);

        return response.data.result;
    } catch (e) {
        if (e.response) {
            throw e.response.data.message || 'User delete failed';
        } else {
            console.log(e);
            throw 'Something went wrong...';
        }
    }
}


export {
    authenticateUser,
    getUsers,
    createUser,
    updateUser,
    deleteUser
}
